import { useParams } from "react-router-dom"

import ShortLinkRedirectCommon from "./ShortLinkRedirectCommon"

export default function AgentLeadShortLinkRedirect() {
  const { key } = useParams()

  return (
    <ShortLinkRedirectCommon apiURL={`/leads/api/short-link-target/${key}/`} />
  )
}
