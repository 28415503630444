import Autocomplete from "@mui/material/Autocomplete"
import Avatar from "@mui/material/Avatar"
import Chip from "@mui/material/Chip"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { useState } from "react"

import Column from "../../../common/components/Column"
import Row from "../../../common/components/Row"
import { useRetrieveAllBrands } from "../../../common/utils"

export default function ExcludeBrandsSelector({
  brand,
  onBrandChanged,
  brandInputValue,
  onBrandInputValueChanged,
  options,
  excludedBrands,
  handleSelectedBrandDeletedWrapper,
}) {
  const excludedBrandIDS = excludedBrands.map(({ id }) => id)
  return (
    <Column sx={{ gap: 2 }}>
      <Autocomplete
        value={brand}
        onChange={onBrandChanged}
        inputValue={brandInputValue}
        onInputChange={onBrandInputValueChanged}
        options={options.filter(
          (option) => excludedBrandIDS.indexOf(option.id) === -1
        )}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Brands"
            placeholder="Brands"
            fullWidth
          />
        )}
        renderOption={(props, option) => (
          <BrandOption option={option} {...props} />
        )}
        sx={{}}
      />
      <Row
        sx={{
          flexWrap: "wrap",
          gap: 1,
        }}
      >
        {excludedBrands.map((brand, index) => (
          <Chip
            key={brand.id}
            size="medium"
            label={brand.name}
            onDelete={handleSelectedBrandDeletedWrapper(index)}
          />
        ))}
      </Row>
    </Column>
  )
}

export function BrandOption({ option, ...props }) {
  return (
    <Row
      component="li"
      {...props}
      sx={{
        alignItems: "center",
        gap: 1,
      }}
    >
      <Avatar src={option.icon} sx={{ width: "32px", height: "32px" }} />
      <Typography variant="body1">{option.name}</Typography>
    </Row>
  )
}

export function useExcludeBrandsSelectorState() {
  const [brandInputValue, setBrandInputValue] = useState("")
  const [brand, setBrand] = useState(null)
  const [excludedBrands, setExcludedBrands] = useState([])
  const { sortedBrands } = useRetrieveAllBrands()

  function handleBrandInputValueChanged(_, newValue) {
    setBrandInputValue(newValue)
  }

  function handleBrandChanged(_, newValue) {
    setBrand(null)
    setBrandInputValue("")
    setExcludedBrands([...excludedBrands, newValue])
  }

  function handleSelectedBrandDeletedWrapper(index) {
    return function () {
      setExcludedBrands(excludedBrands.filter((_, i) => i !== index))
    }
  }

  return {
    brand,
    handleBrandChanged,
    brandInputValue,
    handleBrandInputValueChanged,
    excludedBrands,
    setExcludedBrands,
    handleSelectedBrandDeletedWrapper,
    sortedBrands,
  }
}
