import GolfCourseOutlinedIcon from "@mui/icons-material/GolfCourseOutlined"
import Button from "@mui/material/Button"
import Collapse from "@mui/material/Collapse"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import FormControlLabel from "@mui/material/FormControlLabel"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import Typography from "@mui/material/Typography"
import { useState } from "react"
import { useDispatch } from "react-redux"

import { HaloCircularIcon } from "../../../common/components/CircularIcon"
import Column from "../../../common/components/Column"
import LengthLimitedTextField from "../../../common/components/LengthLimitedTextField"
import LoadingButton from "../../../common/components/LoadingButton"
import { useAuthorizedAxiosClient, useIsMobile } from "../../../common/utils"
import { updateProfile } from "../slice"

export default function SelectSignupReasonDialog({ open, onClose }) {
  const [signupReason, setSignupReason] = useState("")
  const [customSignupReason, setCustomSignupReason] = useState("")
  const [isUpdatingProfile, setIsUpdatingProfile] = useState(false)
  const axios = useAuthorizedAxiosClient()
  const dispatch = useDispatch()
  const isMobile = useIsMobile()

  const SOMETHING_ELSE_OPTION = "Something else"
  const selectedSomethingElseOption = signupReason === SOMETHING_ELSE_OPTION

  const SIGNUP_REASONS = [
    "Improve commission split",
    "Seeking a signing bonus",
    "More training and mentorship",
    "Office culture and location",
    "Leads provided by company",
    "Admin or marketing support",
    SOMETHING_ELSE_OPTION,
  ]

  function handleSignupReasonChanged(e) {
    setSignupReason(e.target.value)
    setCustomSignupReason("")
  }

  function handleSubmitButtonClicked() {
    setIsUpdatingProfile(true)
    axios
      .put("/agents/api/profile/", {
        signup_reason: customSignupReason || signupReason,
      })
      .then(({ data }) => {
        dispatch(updateProfile(data))
        onClose()
      })
      .finally(() => setIsUpdatingProfile(true))
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullScreen={isMobile}>
      <DialogContent>
        <Column sx={{ gap: 2 }}>
          <Column sx={{ alignItems: "center", textAlign: "center", gap: 2 }}>
            <HaloCircularIcon size={64} Icon={GolfCourseOutlinedIcon} />
            <Typography variant="h6">
              What's most important to you right now?
            </Typography>
          </Column>
          <RadioGroup
            value={signupReason}
            onChange={handleSignupReasonChanged}
            sx={{ pl: 1.5 }}
          >
            {SIGNUP_REASONS.map((signupReason, i) => (
              <FormControlLabel
                value={signupReason}
                control={<Radio size="small" />}
                label={signupReason}
                key={i}
              />
            ))}
          </RadioGroup>
          <Collapse in={selectedSomethingElseOption}>
            <Column>
              <LengthLimitedTextField
                label="Thing most important to you"
                maxLength={64}
                multiline
                value={customSignupReason}
                setValue={setCustomSignupReason}
              />
            </Column>
          </Collapse>
        </Column>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          isLoading={isUpdatingProfile}
          disabled={selectedSomethingElseOption && customSignupReason === ""}
          onClick={handleSubmitButtonClicked}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
