import LoadingButton from "@mui/lab/LoadingButton"
import Autocomplete from "@mui/material/Autocomplete"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { useEffect, useState } from "react"

import Column from "../../../common/components/Column"
import { useIsMobile } from "../../../common/utils"
import { BrandOption } from "../../agent/brokerages/exclude-brands-selector"

export default function EditBrandDialog({
  open,
  onClose,
  brokerage,
  brands,
  isSaving,
}) {
  const [selectedBrand, setSelectedBrand] = useState(null)
  const [brandInputValue, setBrandInputValue] = useState("")
  const isMobile = useIsMobile()

  function handleSoftClose() {
    onClose()
  }

  function handleSaveButtonClicked() {
    onClose(selectedBrand)
  }

  useEffect(() => {
    if (brokerage.brand) {
      const brand = brands.find((brand) => brand.id === brokerage.brand)
      setSelectedBrand(brand)
      setBrandInputValue(brand.name)
    } else {
      setSelectedBrand(null)
    }
  }, [brokerage, brands])

  return (
    <Dialog
      open={open}
      onClose={handleSoftClose}
      maxWidth="xs"
      fullWidth
      fullScreen={isMobile}
    >
      <DialogContent>
        <Column sx={{ gap: 2 }}>
          <Typography variant="h6" sx={{ textAlign: "center" }}>
            Update brokerage brand
          </Typography>
          <Autocomplete
            value={selectedBrand}
            onChange={(e, newValue) => setSelectedBrand(newValue)}
            inputValue={brandInputValue}
            onInputChange={(e, newValue) => setBrandInputValue(newValue)}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            options={brands}
            renderOption={(props, option) => (
              <BrandOption option={option} {...props} />
            )}
            renderInput={(props) => <TextField label="Brand" {...props} />}
            fullWidth
          />
        </Column>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={handleSoftClose}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          loading={isSaving}
          disabled={!selectedBrand}
          onClick={handleSaveButtonClicked}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
