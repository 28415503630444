import AddLocationOutlinedIcon from "@mui/icons-material/AddLocationOutlined"
import LoadingButton from "@mui/lab/LoadingButton"
import Button from "@mui/material/Button"
import Collapse from "@mui/material/Collapse"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import Typography from "@mui/material/Typography"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useDebounce } from "usehooks-ts"

import { HaloCircularIcon } from "../../../common/components/CircularIcon"
import Column from "../../../common/components/Column"
import SelectCurrentBrokerageAutocomplete from "../../../common/components/SelectCurrentBrokerageAutocomplete"
import { useAuthorizedAxiosClient, useIsMobile } from "../../../common/utils"
import ExcludeBrandsSelector, {
  useExcludeBrandsSelectorState,
} from "../brokerages/exclude-brands-selector"
import { updateProfile } from "../slice"

export default function SelectCurrentBrokerageAndBrandDialog({
  open,
  onClose,
}) {
  const [currentBrokerage, setCurrentBrokerage] = useState(null)
  const [currentBrokerageInputValue, setCurrentBrokerageInputValue] =
    useState("")
  const debouncedCurrentBrokerageInputValue = useDebounce(
    currentBrokerageInputValue
  )
  const [brokerages, setBrokerages] = useState([])
  const {
    brand,
    handleBrandChanged,
    brandInputValue,
    handleBrandInputValueChanged,
    excludedBrands,
    setExcludedBrands,
    handleSelectedBrandDeletedWrapper,
    sortedBrands,
  } = useExcludeBrandsSelectorState()
  const [isLoading, setIsLoading] = useState(false)
  const isMobile = useIsMobile()
  const axios = useAuthorizedAxiosClient()
  const dispatch = useDispatch()

  function handleCurrentBrokerageChanged(newBrokerage) {
    if (newBrokerage.brand) {
      setExcludedBrands(
        sortedBrands.filter((brand) => brand.id === newBrokerage.brand)
      )
    } else {
      setExcludedBrands([])
    }
    setCurrentBrokerage(newBrokerage)
  }

  function handleSaveButtonClicked() {
    submitResponse("selected", {
      current_brokerage: currentBrokerage.id,
      brands: excludedBrands.map(({ id }) => id),
    })
  }

  function handleImNewButtonClicked() {
    submitResponse("new_")
  }

  function submitResponse(dialogResponse, data = {}) {
    setIsLoading(true)
    axios
      .post("/agents/api/select-brokerage-and-brand/", {
        dialog_response: dialogResponse,
        ...data,
      })
      .then(({ data }) => {
        dispatch(updateProfile(data))
        onClose()
      })
      .finally(() => setIsLoading(false))
  }

  useEffect(
    () => {
      if (!debouncedCurrentBrokerageInputValue) {
        setBrokerages([])
      } else {
        setIsLoading(true)
        axios
          .get("/agents/api/select-current-brokerage-search/", {
            params: { q: debouncedCurrentBrokerageInputValue },
          })
          .then(({ data }) => setBrokerages(data.results))
          .finally(() => setIsLoading(false))
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedCurrentBrokerageInputValue]
  )

  return (
    <Dialog
      open={open}
      onClose={null}
      maxWidth="sm"
      fullWidth
      fullScreen={isMobile}
    >
      <DialogContent>
        <Column sx={{ gap: 2 }}>
          <Column sx={{ alignItems: "center", textAlign: "center", gap: 2 }}>
            <HaloCircularIcon Icon={AddLocationOutlinedIcon} size={64} />
            <Typography variant="h6">
              Please indicate your current brokerage
            </Typography>
          </Column>
          <Typography variant="body1" color="text.secondary2">
            In order to present your profile to the brokerage which fit you the
            best, we are asking you to share information about your current
            brokerage.
          </Typography>
          <SelectCurrentBrokerageAutocomplete
            currentBrokerage={currentBrokerage}
            setCurrentBrokerage={handleCurrentBrokerageChanged}
            currentBrokerageInputValue={currentBrokerageInputValue}
            setCurrentBrokerageInputValue={setCurrentBrokerageInputValue}
            brokerageOptions={brokerages}
          />
          <Collapse in={!!currentBrokerage}>
            <Column sx={{ gap: 2, mt: 1 }}>
              <Typography variant="body1" color="text.secondary2">
                Please also select the brands you're not interested in working
                with.
              </Typography>
              <ExcludeBrandsSelector
                brand={brand}
                onBrandChanged={handleBrandChanged}
                brandInputValue={brandInputValue}
                onBrandInputValueChanged={handleBrandInputValueChanged}
                excludedBrands={excludedBrands}
                handleSelectedBrandDeletedWrapper={
                  handleSelectedBrandDeletedWrapper
                }
                options={sortedBrands}
              />
            </Column>
          </Collapse>
        </Column>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleImNewButtonClicked}
        >
          I'm new
        </Button>
        <LoadingButton
          variant="contained"
          loading={isLoading}
          disabled={!currentBrokerage}
          onClick={handleSaveButtonClicked}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
