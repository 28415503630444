import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import Link from "@mui/material/Link"
import Switch from "@mui/material/Switch"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link as RouterLink } from "react-router-dom"
import { useDebounce } from "usehooks-ts"

import ChangePasswordForm from "../../../common/components/ChangePasswordForm"
import Column from "../../../common/components/Column"
import Row from "../../../common/components/Row"
import SettingsSection, {
  SettingsSectionContainer,
} from "../../../common/components/SettingsSection"
import TabContainer from "../../../common/components/TabContainer"
import {
  useAuthorizedAxiosClient,
  useShowBottomNav,
} from "../../../common/utils"
import DistanceUnitsSelector, {
  useDistanceUnitsSelectorState,
} from "../brokerages/distance-units-selector"
import ExcludeBrandsSelector, {
  useExcludeBrandsSelectorState,
} from "../brokerages/exclude-brands-selector"
import {
  profileLoading,
  selectProfile,
  updateProfile,
  updateProfileProperty,
} from "../slice"
import { useHandleToggleChanged } from "./utils"

export const PREFERENCES_TAB_NAME = "prefs"
export const NOTIFICATIONS_TAB_NAME = "notifications"
export const ACCOUNT_TAB_NAME = "account"
export const VISIBILITY_TAB_NAME = "visibility"

export default function Settings() {
  const PREFERENCES_TAB_INDEX = 0
  const NOTIFICATIONS_TAB_INDEX = 1
  const ACCOUNT_MANAGEMENT_TAB_INDEX = 2

  let DEFAULT_TAB_INDEX = PREFERENCES_TAB_INDEX
  const [currentTabIndex, setCurrentTabIndex] = useState(DEFAULT_TAB_INDEX)
  const [zipcode, setZipcode] = useState("")
  const debouncedZipcode = useDebounce(zipcode, 500)
  const [zipcodeError, setZipcodeError] = useState(null)
  const {
    distance,
    setDistance,
    handleDistanceChanged,
    setDistanceError,
    units,
    setUnits,
    handleUnitsToggleChanged,
  } = useDistanceUnitsSelectorState()
  const debouncedDistance = useDebounce(distance, 500)
  const {
    brand,
    handleBrandChanged,
    brandInputValue,
    handleBrandInputValueChanged,
    excludedBrands,
    setExcludedBrands,
    handleSelectedBrandDeletedWrapper,
    sortedBrands,
  } = useExcludeBrandsSelectorState()
  const [getNewConnectionEmail, setGetNewConnectionEmail] = useState(true)
  const [getNewBrokerageEmail, setGetNewBrokerageEmail] = useState(true)
  const [getNewBrokeragesMightLikeEmail, setGetNewBrokeragesMightLikeEmail] =
    useState(true)
  const [getSmartsetterUpdatesEmail, setGetSmartsetterUpdatesEmail] =
    useState(true)
  const [getNewConnectionNotification, setGetNewConnectionNotification] =
    useState(true)
  const [getNewBrokerageNotification, setGetNewBrokerageNotification] =
    useState(true)
  const [
    getNewBrokeragesMightLikeNotification,
    setGetNewBrokeragesMightLikeNotification,
  ] = useState(true)
  const [
    getSmartsetterUpdatesNotification,
    setGetSmartsetterUpdatesNotification,
  ] = useState(true)

  const profile = useSelector(selectProfile)
  const isProfileLoading = useSelector(profileLoading)
  const axios = useAuthorizedAxiosClient()
  const dispatch = useDispatch()
  const handleToggleChanged = useHandleToggleChanged()
  const showBottomNav = useShowBottomNav()

  function handleTabChanged(e, newTab) {
    setCurrentTabIndex(newTab)
  }

  function handleZipcodeChanged(e) {
    setZipcodeError(null)
    setZipcode(e.target.value)
  }

  function handleDistanceChangedWrapper(e) {
    handleDistanceChanged(e)
  }

  function handleUnitsToggleChangedWrapper(_, newValue) {
    handleUnitsToggleChanged(_, newValue)
    updateSettingsProperty({ distance_units: newValue }).then(() => {
      dispatch(
        updateProfileProperty({ prop: "distance_units", value: newValue })
      )
    })
  }

  function handleBrandChangedWrapper(_, newValue) {
    handleBrandChanged(_, newValue)
    const brandIDs = [newValue.id, ...excludedBrands.map(({ id }) => id)]
    updateSettingsProperty({ excluded_brands_update: brandIDs }).then(
      ({ data }) =>
        dispatch(
          updateProfileProperty({
            prop: "excluded_brands",
            value: data.excluded_brands,
          })
        )
    )
  }

  function handleSelectedBrandDeletedWrapper2(index) {
    return function () {
      handleSelectedBrandDeletedWrapper(index)()
      updateSettingsProperty({
        excluded_brands_update: excludedBrands
          .filter((_, i) => i !== index)
          .map(({ id }) => id),
      }).then(({ data }) =>
        updateProfileProperty({
          prop: "excluded_brands",
          value: data.excluded_brands,
        })
      )
    }
  }

  function handleToggleChangedWrapper(setter, propName) {
    return function (e) {
      const newValue = e.target.checked
      handleToggleChanged(propName, setter, newValue)
    }
  }

  function updateSettingsProperty(data) {
    return axios.put("/agents/api/profile/", data)
  }

  useEffect(
    () => {
      if (!isProfileLoading) {
        setZipcode(profile.zip_code)
        setDistance(profile.distance_from_work)
        setUnits(profile.distance_units || "Mi")
        setGetNewConnectionEmail(profile.receive_new_connection_email)
        setGetNewBrokerageEmail(profile.receive_new_brokerage_email)
        setGetNewBrokeragesMightLikeEmail(
          profile.receive_new_possible_brokerage_email
        )
        setGetSmartsetterUpdatesEmail(profile.receive_product_updates_email)
        setGetNewConnectionNotification(
          profile.receive_new_connection_notification
        )
        setGetNewBrokerageNotification(
          profile.receive_new_brokerage_notification
        )
        setGetNewBrokeragesMightLikeNotification(
          profile.receive_new_possible_brokerage_notification
        )
        setGetSmartsetterUpdatesNotification(
          profile.receive_product_updates_notification
        )
        setExcludedBrands(profile.excluded_brands)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [profile, isProfileLoading]
  )

  useEffect(
    () => {
      if (isProfileLoading || !debouncedZipcode) {
        return
      }
      if (debouncedZipcode === profile.zip_code) {
        setZipcodeError(null)
      } else {
        updateSettingsProperty({ zip_code: debouncedZipcode })
          .then(({ data }) => {
            dispatch(updateProfile(data))
          })
          .catch(() => {
            setZipcodeError("Invalid zip code")
          })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [profile, isProfileLoading, debouncedZipcode]
  )

  useEffect(
    () => {
      if (isProfileLoading || !debouncedDistance) {
        return
      }
      if (debouncedDistance === profile.distance_from_work) {
        setDistanceError(null)
      } else {
        updateSettingsProperty({ distance_from_work: debouncedDistance }).then(
          () => {
            dispatch(
              updateProfileProperty({
                prop: "distance_from_work",
                value: debouncedDistance,
              })
            )
          }
        )
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [profile, isProfileLoading, debouncedDistance]
  )

  return (
    <Column
      sx={{
        alignItems: "center",
        maxWidth: showBottomNav ? undefined : "800px",
        mx: showBottomNav ? 2 : "auto",
        py: 4,
      }}
    >
      <Column>
        <Tabs
          value={currentTabIndex}
          onChange={handleTabChanged}
          variant="scrollable"
          sx={{ maxWidth: "calc(100vw - 48px)" }}
        >
          <Tab label="Preferences" />
          <Tab label="Notifications" />
          <Tab label="Account Management" />
        </Tabs>
        <Column>
          <TabContainer value={currentTabIndex} index={PREFERENCES_TAB_INDEX}>
            <SettingsSection
              title="Location preference"
              subtitle={
                <>
                  This determines the default brokerages that are recommended to
                  you on the{" "}
                  <Link
                    variant="inherit"
                    color="primary"
                    component={RouterLink}
                    to="/agent/brokerages"
                  >
                    brokerages
                  </Link>{" "}
                  page.
                </>
              }
            >
              <SettingsRow
                title="Zip Code"
                description="Helps determine your approximate location"
                mt={6}
              >
                <TextField
                  error={!!zipcodeError}
                  helperText={zipcodeError}
                  value={zipcode}
                  onChange={handleZipcodeChanged}
                  sx={{ maxWidth: "8em", ml: 2 }}
                />
              </SettingsRow>
              <Divider sx={{ mt: 4 }} />
              <SettingsRow
                title="Radius"
                description="The absolute distance of brokerages from your location"
              >
                <Box sx={{ maxWidth: "14em" }}>
                  <DistanceUnitsSelector
                    distance={distance}
                    onDistanceChanged={handleDistanceChangedWrapper}
                    units={units}
                    onUnitsChanged={handleUnitsToggleChangedWrapper}
                    onError={setDistanceError}
                  />
                </Box>
              </SettingsRow>
            </SettingsSection>
            <SettingsSection
              title="Exclude brokerages"
              subtitle="Brokerages your're not interested in working with."
            >
              <Column
                sx={{
                  mt: 4,
                  gap: 2,
                }}
              >
                <ExcludeBrandsSelector
                  brand={brand}
                  onBrandChanged={handleBrandChangedWrapper}
                  brandInputValue={brandInputValue}
                  onBrandInputValueChanged={handleBrandInputValueChanged}
                  excludedBrands={excludedBrands}
                  handleSelectedBrandDeletedWrapper={
                    handleSelectedBrandDeletedWrapper2
                  }
                  options={sortedBrands}
                />
              </Column>
            </SettingsSection>
          </TabContainer>
          <TabContainer index={NOTIFICATIONS_TAB_INDEX} value={currentTabIndex}>
            <SettingsSection
              title="Email notifications"
              subtitle="Here you can control which emails you get."
            >
              <ToggleGroupContainer>
                <DescribedToggle
                  title="New connections"
                  description="Get an email when a brokerage responds to your proposal."
                  checked={getNewConnectionEmail}
                  onChange={handleToggleChangedWrapper(
                    setGetNewConnectionEmail,
                    "receive_new_connection_email"
                  )}
                />
                <Divider />
                <DescribedToggle
                  title="New brokerages in your area"
                  description="Get an email when a new brokerage enters your area."
                  checked={getNewBrokerageEmail}
                  onChange={handleToggleChangedWrapper(
                    setGetNewBrokerageEmail,
                    "receive_new_brokerage_email"
                  )}
                />
                <Divider />
                <DescribedToggle
                  title="New brokerages you might like"
                  description="Get an email when we find a new brokerage a little further away than your distance setting."
                  checked={getNewBrokeragesMightLikeEmail}
                  onChange={handleToggleChangedWrapper(
                    setGetNewBrokeragesMightLikeEmail,
                    "receive_new_possible_brokerage_email"
                  )}
                />
                <Divider />
                <DescribedToggle
                  title="SmartSetter updates"
                  description="Get an email when we add a new feature or fix a critical issue."
                  checked={getSmartsetterUpdatesEmail}
                  onChange={handleToggleChangedWrapper(
                    setGetSmartsetterUpdatesEmail,
                    "receive_product_updates_email"
                  )}
                />
              </ToggleGroupContainer>
            </SettingsSection>
            <SettingsSection
              title="App notifications"
              subtitle="Control which notifications you get in the app bar and notifications page"
            >
              <ToggleGroupContainer>
                <DescribedToggle
                  title="New connections"
                  description="Get a notification when a brokerage responds to your proposal."
                  checked={getNewConnectionNotification}
                  onChange={handleToggleChangedWrapper(
                    setGetNewConnectionNotification,
                    "receive_new_connection_notification"
                  )}
                />
                <Divider />
                <DescribedToggle
                  title="New brokerages in your area"
                  description="Get a notification when a new brokerage enters your area."
                  checked={getNewBrokerageNotification}
                  onChange={handleToggleChangedWrapper(
                    setGetNewBrokerageNotification,
                    "receive_new_brokerage_notification"
                  )}
                />
                <Divider />
                <DescribedToggle
                  title="New brokerages you might like"
                  description="Get a notification when we find a new brokerage a little further away than your distance setting."
                  checked={getNewBrokeragesMightLikeNotification}
                  onChange={handleToggleChangedWrapper(
                    setGetNewBrokeragesMightLikeNotification,
                    "receive_new_possible_brokerage_notification"
                  )}
                />
                <Divider />
                <DescribedToggle
                  title="SmartSetter updates"
                  description="Get a notification when we add a new feature or fix a critical issue."
                  checked={getSmartsetterUpdatesNotification}
                  onChange={handleToggleChangedWrapper(
                    setGetSmartsetterUpdatesNotification,
                    "receive_product_updates_notification"
                  )}
                />
              </ToggleGroupContainer>
            </SettingsSection>
          </TabContainer>
          <TabContainer
            value={ACCOUNT_MANAGEMENT_TAB_INDEX}
            index={currentTabIndex}
          >
            <SettingsSectionContainer>
              <ChangePasswordForm />
            </SettingsSectionContainer>
          </TabContainer>
        </Column>
      </Column>
    </Column>
  )
}

function SettingsRow({ title, description, mt = 4, children }) {
  return (
    <Row
      sx={{
        justifyContent: "space-between",
        alignItems: "center",
        mt: mt,
      }}
    >
      <WidgetTitleDescription title={title} description={description} />
      {children}
    </Row>
  )
}

function WidgetTitleDescription({
  title,
  description,
  ml = 0,
  subtitleMaxWidth = "30em",
}) {
  return (
    <Column sx={{ ml: ml }}>
      <Typography variant="h6" fontSize="1.1rem">
        {title}
      </Typography>
      <Typography
        variant="body1"
        sx={{ color: "text.secondary2", maxWidth: "30em" }}
      >
        {description}
      </Typography>
    </Column>
  )
}

function ToggleGroupContainer({ children }) {
  return (
    <Column
      sx={{
        mt: 4,
        "& .MuiDivider-root": {
          mt: 2,
        },
        "& > .MuiBox-root:nth-of-type(n + 2)": {
          mt: 2,
        },
      }}
    >
      {children}
    </Column>
  )
}

function DescribedToggle({ title, description, checked, onChange }) {
  return (
    <Row sx={{ alignItems: "center" }}>
      <Switch
        color="primary"
        checked={checked}
        onChange={onChange}
        disabled={!onChange}
      />
      <WidgetTitleDescription title={title} description={description} ml={2} />
    </Row>
  )
}
