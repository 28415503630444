import { useParams } from "react-router-dom"

import ShortLinkRedirectCommon from "./ShortLinkRedirectCommon"

export default function ShortLinkRedirect() {
  const { key } = useParams()

  return (
    <ShortLinkRedirectCommon
      apiURL={`/link-shortener/api/short-link-target/${key}/`}
    />
  )
}
