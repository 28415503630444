import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined"
import Autocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"

import BrandAvatar from "./BrandAvatar"
import Column from "./Column"
import Row from "./Row"

export default function SelectCurrentBrokerageAutocomplete({
  currentBrokerage,
  setCurrentBrokerage,
  currentBrokerageInputValue,
  setCurrentBrokerageInputValue,
  brokerageOptions,
}) {
  return (
    <Autocomplete
      value={currentBrokerage}
      onChange={(_, value) => setCurrentBrokerage(value)}
      inputValue={currentBrokerageInputValue}
      onInputChange={(_, newValue) => setCurrentBrokerageInputValue(newValue)}
      renderInput={(props) => (
        <TextField
          {...props}
          label="Your current brokerage"
          placeholder="Please select your current brokerage"
        />
      )}
      options={brokerageOptions}
      getOptionLabel={(option) => option.company}
      noOptionsText={
        currentBrokerageInputValue.length === 0
          ? "Type to search.."
          : "No brokerages found"
      }
      renderOption={(props, option) => (
        <Row sx={{ alignItems: "center", p: 2 }} {...props}>
          <BrandAvatar brand={{ icon: option.image }} />
          <Column sx={{ ml: 2 }}>
            <Typography variant="caption">{option.company}</Typography>
            <Row sx={{ alignItems: "center" }}>
              <LocationOnOutlinedIcon
                sx={{ color: "text.secondary2" }}
                fontSize="small"
              />
              <Typography
                variant="body1"
                color="text.secondary2"
                sx={{ ml: 0.5 }}
              >
                {option.address}
              </Typography>
            </Row>
          </Column>
        </Row>
      )}
    />
  )
}
