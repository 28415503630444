import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import { axiosClient } from "../../common/utils"

export default function ShortLinkRedirectCommon({ apiURL }) {
  const navigate = useNavigate()

  useEffect(() => {
    axiosClient.get(apiURL).then(({ data }) => navigate(data.target))
  })
}
